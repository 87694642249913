<template>
  <div>
    <div class="container">
      <div class="center">
        <h2 class="page-title text-center">
          {{ "النسخ الأحتياطي لقواعد البيانات" | translate }}
        </h2>
      </div>
      <hr />
      <div class="row g-5">
        <div class="col-md-6">
          <div class="cont d-flex flex-column align-items-center p-2">
            <h4>Backup Database</h4>
            <hr />
            <select class="form-control" v-model="backupFile">
              <option value="Devices">Devices</option>
              <option value="Categories">Categories</option>
              <option value="Records">Records</option>
              <option value="Users">Users</option>
              <option value="Locations">Locations</option>
            </select>
            <hr />
            <button
              :disabled="!backupFile"
              @click="backupDatabase"
              class="btn btn-success w-75 py-4"
            >
              Backup database
            </button>
          </div>
          <hr />
        </div>
        <div class="col-md-6">
          <div class="cont d-flex flex-column align-items-center p-2">
            <h4>Restore Database</h4>
            <hr />
            <select class="form-control" v-model="restoreFile">
              <option value="Devices">Devices</option>
              <option value="Categories">Categories</option>
              <option value="Records">Records</option>
              <option value="Users">Users</option>
              <option value="Locations">Locations</option>
            </select>
            <hr />
            <input @change="readJsonFile" type="file" class="form-control" />
            <hr />
            <button
              :disabled="!backupFile || !file"
              @click="restoreDatabase"
              class="btn btn-danger w-75 py-4"
            >
              Restore database
            </button>
          </div>
          <hr />
        </div>
        <div class="col-md-12">
          <div v-if="loading" class="center">
            <loading />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      backupFile: "Users",
      restoreFile: "Users",
      file: null,
      loading: false,
      progress: null,
    };
  },
  methods: {
    backupDatabase() {
      this.loading = true;
      axios.get("/backupDatabase/" + this.backupFile).then((res) => {
        this.loading = false;
        const a = document.createElement("a");
        const data = res.data.data;
        const json = JSON.stringify(data);
        const blob = new Blob([json], { type: "octet/stream" });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = this.backupFile + ".json";
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
    restoreDatabase() {
      this.loading = true;
      const formData = new FormData();
      formData.append("collection", this.restoreFile);
      formData.append("jsonFile", this.file);
      this.progress = null;
      axios
        .put("/restoreDatabase", formData, {
          onUploadProgress: (progressEvent) => {
            this.progress = Math.round(
              (+progressEvent.loaded / +progressEvent.total) * 100
            );
            if (this.progress == 100) this.progress = null;
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.data.err) {
            return this.$store.dispatch("writeMessage", res.data.err);
          }
          this.$store.dispatch("writeMessage", "تم رفع معولمات الداتابيز المحددة بنجاح");
        });
    },
    readJsonFile(e) {
      const file = e.target.files[0];
      if (file.type.split("/")[1] != "json") {
        alert("مسموح بملفات JSON فقط");
        return;
      }
      this.file = file;
    },
  },
};
</script>

<style lang="scss" scoped></style>
